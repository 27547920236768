// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contratado-js": () => import("./../../../src/pages/contratado.js" /* webpackChunkName: "component---src-pages-contratado-js" */),
  "component---src-pages-cotizar-auto-js": () => import("./../../../src/pages/cotizar-auto.js" /* webpackChunkName: "component---src-pages-cotizar-auto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-preguntas-frecuentes-cobertura-js": () => import("./../../../src/pages/preguntas-frecuentes/cobertura.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-cobertura-js" */),
  "component---src-pages-preguntas-frecuentes-documentacion-js": () => import("./../../../src/pages/preguntas-frecuentes/documentacion.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-documentacion-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-preguntas-frecuentes-pagos-js": () => import("./../../../src/pages/preguntas-frecuentes/pagos.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-pagos-js" */),
  "component---src-pages-preguntas-frecuentes-reclamos-js": () => import("./../../../src/pages/preguntas-frecuentes/reclamos.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-reclamos-js" */),
  "component---src-pages-preguntas-frecuentes-siniestros-js": () => import("./../../../src/pages/preguntas-frecuentes/siniestros.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-siniestros-js" */),
  "component---src-pages-resultados-js": () => import("./../../../src/pages/resultados.js" /* webpackChunkName: "component---src-pages-resultados-js" */),
  "component---src-pages-seguros-comercio-js": () => import("./../../../src/pages/seguros/comercio.js" /* webpackChunkName: "component---src-pages-seguros-comercio-js" */),
  "component---src-pages-seguros-cotizar-art-contratado-js": () => import("./../../../src/pages/seguros/cotizar-art/contratado.js" /* webpackChunkName: "component---src-pages-seguros-cotizar-art-contratado-js" */),
  "component---src-pages-seguros-cotizar-art-index-js": () => import("./../../../src/pages/seguros/cotizar-art/index.js" /* webpackChunkName: "component---src-pages-seguros-cotizar-art-index-js" */),
  "component---src-pages-seguros-cotizar-art-resultados-js": () => import("./../../../src/pages/seguros/cotizar-art/resultados.js" /* webpackChunkName: "component---src-pages-seguros-cotizar-art-resultados-js" */),
  "component---src-pages-seguros-cotizar-celular-js": () => import("./../../../src/pages/seguros/cotizar-celular.js" /* webpackChunkName: "component---src-pages-seguros-cotizar-celular-js" */),
  "component---src-pages-seguros-cotizar-hogar-js": () => import("./../../../src/pages/seguros/cotizar-hogar.js" /* webpackChunkName: "component---src-pages-seguros-cotizar-hogar-js" */),
  "component---src-pages-seguros-cotizar-notebook-js": () => import("./../../../src/pages/seguros/cotizar-notebook.js" /* webpackChunkName: "component---src-pages-seguros-cotizar-notebook-js" */),
  "component---src-pages-seguros-hogar-js": () => import("./../../../src/pages/seguros/hogar.js" /* webpackChunkName: "component---src-pages-seguros-hogar-js" */),
  "component---src-pages-seguros-js": () => import("./../../../src/pages/seguros.js" /* webpackChunkName: "component---src-pages-seguros-js" */),
  "component---src-pages-seguros-tecnologia-js": () => import("./../../../src/pages/seguros/tecnologia.js" /* webpackChunkName: "component---src-pages-seguros-tecnologia-js" */),
  "component---src-pages-siniestros-allianz-js": () => import("./../../../src/pages/siniestros/allianz.js" /* webpackChunkName: "component---src-pages-siniestros-allianz-js" */),
  "component---src-pages-siniestros-berkley-js": () => import("./../../../src/pages/siniestros/berkley.js" /* webpackChunkName: "component---src-pages-siniestros-berkley-js" */),
  "component---src-pages-siniestros-experta-js": () => import("./../../../src/pages/siniestros/experta.js" /* webpackChunkName: "component---src-pages-siniestros-experta-js" */),
  "component---src-pages-siniestros-galeno-js": () => import("./../../../src/pages/siniestros/galeno.js" /* webpackChunkName: "component---src-pages-siniestros-galeno-js" */),
  "component---src-pages-siniestros-galicia-seguros-js": () => import("./../../../src/pages/siniestros/galicia-seguros.js" /* webpackChunkName: "component---src-pages-siniestros-galicia-seguros-js" */),
  "component---src-pages-siniestros-js": () => import("./../../../src/pages/siniestros.js" /* webpackChunkName: "component---src-pages-siniestros-js" */),
  "component---src-pages-siniestros-mapfre-js": () => import("./../../../src/pages/siniestros/mapfre.js" /* webpackChunkName: "component---src-pages-siniestros-mapfre-js" */),
  "component---src-pages-siniestros-mercantil-andina-js": () => import("./../../../src/pages/siniestros/mercantil-andina.js" /* webpackChunkName: "component---src-pages-siniestros-mercantil-andina-js" */),
  "component---src-pages-siniestros-orbis-js": () => import("./../../../src/pages/siniestros/orbis.js" /* webpackChunkName: "component---src-pages-siniestros-orbis-js" */),
  "component---src-pages-siniestros-parana-js": () => import("./../../../src/pages/siniestros/parana.js" /* webpackChunkName: "component---src-pages-siniestros-parana-js" */),
  "component---src-pages-siniestros-provincia-js": () => import("./../../../src/pages/siniestros/provincia.js" /* webpackChunkName: "component---src-pages-siniestros-provincia-js" */),
  "component---src-pages-siniestros-rus-js": () => import("./../../../src/pages/siniestros/rus.js" /* webpackChunkName: "component---src-pages-siniestros-rus-js" */),
  "component---src-pages-siniestros-san-cristobal-js": () => import("./../../../src/pages/siniestros/san-cristobal.js" /* webpackChunkName: "component---src-pages-siniestros-san-cristobal-js" */),
  "component---src-pages-siniestros-sancor-js": () => import("./../../../src/pages/siniestros/sancor.js" /* webpackChunkName: "component---src-pages-siniestros-sancor-js" */)
}

